import {asText, isFilled} from '@prismicio/helpers';
import type {ContentRelationshipField, FilledContentRelationshipField, PrismicDocument, RichTextField} from '@prismicio/types';
import type {LocationQuery} from 'vue-router';
import type {
    ArticleDocumentData,
    CollectionDocument,
    CollectionDocumentData,
    GuideDocumentData,
    SelectionSlice,
    SuccessStoryDocumentData,
} from '@prismicio-types';
import {usePrismicTypeGuards} from '#imports';

export type FilledContent =
    | FilledContentRelationshipField<'article', string, ArticleDocumentData>
    | FilledContentRelationshipField<'guide', string, GuideDocumentData>
    | FilledContentRelationshipField<'successStory', string, SuccessStoryDocumentData>
    | FilledContentRelationshipField<'collection', string, CollectionDocumentData>;

const {hasProperty} = usePrismicTypeGuards();
function getPublicationDate(content: unknown): Date | null {
    if (hasProperty(content, 'releaseDate') && typeof content.releaseDate === 'string') {
        return new Date(content.releaseDate);
    }
    if (hasProperty(content, 'data') && hasProperty(content.data, 'releaseDate') && typeof content.data.releaseDate === 'string') {
        return new Date(content.data.releaseDate);
    }
    if (hasProperty(content, 'first_publication_date') && typeof content.first_publication_date === 'string') {
        return new Date(content.first_publication_date);
    }
    return null;
}

function isDefinedDocument<T extends PrismicDocument>(document: T | null | undefined): document is T {
    return document !== null && document !== undefined;
}

function locationQueryToQueryString(query: LocationQuery): string {
    if (query === null || Object.keys(query).length === 0) {
        return '';
    }
    const queryParams = Object.entries(query)
        .filter(([_, value]) => value !== null && !Array.isArray(value) && value.length > 0)
        .map(([key, value]) => `${key}=${typeof value === 'string' ? value : value?.filter((item) => item !== null).join(',')}`);
    if (queryParams.length === 0) {
        return '';
    }
    return `?${queryParams.join('&')}`;
}

function isFilledContentRelationship(content: ContentRelationshipField): content is FilledContent {
    return isFilled.contentRelationship<string, string, {}>(content);
}

function titleFieldToId(field: RichTextField) {
    const text = asText(field)!.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
    return text?.join('-').toLowerCase();
}

function getCollectionNumbers(collection: CollectionDocument | FilledContentRelationshipField<'collection', string, CollectionDocumentData>) {
    if (!collection.data) {
        return {};
    }
    return {
        article:
            collection.data.slices
                .filter((slice) => slice.slice_type === 'articles_list')
                .reduce<number>((total, slice) => (slice.variation === 'selection' ? total + slice.items.length : total + 3), 0) +
            collection.data.slices
                .filter((slice) => slice.slice_type === 'selection')
                .reduce(
                    (total, slice) =>
                        total +
                        (slice as SelectionSlice).items.filter(
                            (item) => isFilled.contentRelationship(item.content) && item.content.type === 'article',
                        ).length,
                    0,
                ),
        guide:
            collection.data.slices
                .filter((slice) => slice.slice_type === 'guides_list')
                .reduce((total, slice) => (slice.variation === 'selection' ? total + slice.items.length : total + 3), 0) +
            collection.data.slices
                .filter((slice) => slice.slice_type === 'selection')
                .reduce(
                    (total, slice) =>
                        total +
                        (slice as SelectionSlice).items.filter((item) => isFilled.contentRelationship(item.content) && item.content.type === 'guide')
                            .length,
                    0,
                ),
        event: collection.data.slices
            .filter((slice) => slice.slice_type === 'events_list')
            .reduce((total, slice) => (slice.variation === 'selection' ? total + slice.items.length : total + 3), 0),
        successStory:
            collection.data.slices
                .filter((slice) => slice.slice_type === 'success_stories_list')
                .reduce((total, slice) => (slice.variation === 'selection' ? total + slice.items.length : total + 3), 0) +
            collection.data.slices
                .filter((slice) => slice.slice_type === 'selection')
                .reduce(
                    (total, slice) =>
                        total +
                        (slice as SelectionSlice).items.filter(
                            (item) => isFilled.contentRelationship(item.content) && item.content.type === 'successStory',
                        ).length,
                    0,
                ),
    };
}
export function useContentHelpers() {
    return {
        getPublicationDate,
        isDefinedDocument,
        locationQueryToQueryString,
        isFilledContentRelationship,
        titleFieldToId,
        getCollectionNumbers,
    };
}
